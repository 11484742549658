body, h1, a{
  color: #022B49;
}
body {
  margin: 0;
  font-family: 'Verdana','Overpass',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes headerAppearence {
  from {
    max-height: 10px;
    overflow: hidden;
  }

  to {
    max-height: 500px;
    height: 500px;
  }
}

@keyframes loginAppearence {
  0% {
    opacity: 0;
  }
  20%{
    opacity: 0;
    top: -400px;
  }
  75%{
    opacity: 1;
    top: -230px;
  }
  100%{
    top: -250px;
  }
}
